.inTime {
  display: inline-block;
  margin-right: 2em;
}

.outTime {
  display: inline-block;
}

.outTimeCheckbox {
  display: inline-block;
  margin-left: 1em;
  margin-top: 0;
  margin-bottom: 0;
}
